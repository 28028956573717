import {
  KameleonBlockComponent,
  KameleonFooterBlockComponent,
} from "kameleon-elements";
import { DynamicLoaderProps } from "./types";

export const ZilchComponentFactory = ({
  component,
  blockProps,
  secondaryBlock,
  tertiaryBlock,
  isFooter,
  footerWidgets,
}: DynamicLoaderProps) => {
  if (isFooter) {
    return KameleonFooterBlockComponent({
      element: component,
      kameleonProps: {
        blockProps,
        widgetBlocks: footerWidgets,
      },
    });
  }

  return KameleonBlockComponent({
    element: component,
    kameleonProps: {
      secondaryBlockPackageName: secondaryBlock?.packageName,
      secondaryBlockProps: secondaryBlock?.blockProps,
      tertiaryBlockPackageName: tertiaryBlock?.packageName,
      tertiaryBlockProps: tertiaryBlock?.blockProps,
      blockProps,
    },
  });
};
