import React, { PropsWithChildren, ReactElement, useState } from "react";
import {
  KameleonThemeProvider,
  ZilchPresets,
} from "@kameleon-util/global-theme";
import { graphql, Script, useStaticQuery } from "gatsby";
import { ErrorBoundary } from "react-error-boundary";
import Footer from "./footer";
import { DevelopmentConnectionError } from "./development/development-connection-error";
import "../styles/main.scss";
import { escapeGraphQLKeywordsIn } from "../../utils/json-utils";
import Header from "./header";
import { KameleonIconContext } from "@kameleon-common/icons";
import { NavigationProvider } from "../util-components/header-provider";
import { fallBackRender, logError } from "../util-components/error-utils";
import { merge } from "theme-ui";

export default function Layout({ children }: PropsWithChildren): ReactElement {
  const [webfontLoaded, setWebfontLoaded] = useState(false);

  const { kameleonSettings } = useStaticQuery(graphql`
    query ThemeSettings {
      kameleonSettings {
        theme
        profile {
          toneOfVoice
        }
      }
    }
  `);

  const preset =
    (ZilchPresets as any)[kameleonSettings?.profile?.toneOfVoice ?? ""] ??
    undefined;
  const theme = escapeGraphQLKeywordsIn(kameleonSettings?.theme ?? {}, true);

  const content = (
    <>
      <Script src="/webfont.js" onLoad={(): void => setWebfontLoaded(true)} />
      {webfontLoaded && (
        <KameleonThemeProvider theme={merge(preset?.() ?? {}, theme)}>
          <NavigationProvider>
            <KameleonIconContext.Provider
              value={kameleonSettings?.theme?.fonts.icons}
            >
              <Header />

              <main className="zilch--layout">{children}</main>

              <Footer />
            </KameleonIconContext.Provider>
          </NavigationProvider>
        </KameleonThemeProvider>
      )}
    </>
  );

  return (
    <DevelopmentConnectionError>
      {process.env.NODE_ENV !== "production" ? (
        <ErrorBoundary fallbackRender={fallBackRender} onError={logError}>
          {content}
        </ErrorBoundary>
      ) : (
        content
      )}
    </DevelopmentConnectionError>
  );
}
