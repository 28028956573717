import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import DynamicLoader from "../util-components/dynamic-loader";

export default function Footer() {
  const { kameleonFooter } = useStaticQuery(graphql`
    query KameleonFooter {
      kameleonFooter {
        block
        widgets {
          packageName
          blockProps
        }
      }
    }
  `);

  const block = kameleonFooter?.block;
  if (!block) return null;

  return (
    <DynamicLoader
      isFooter
      component={block?.packageName}
      footerWidgets={kameleonFooter.widgets}
      blockProps={{
        ...(block?.blockProps ?? {}),
      }}
    />
  );
}
