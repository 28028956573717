import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { NavigationContext } from "../util-components/header-provider";
import DynamicLoader from "../util-components/dynamic-loader";

export default function Header() {
  const { isAbsolute } = useContext(NavigationContext);

  const { kameleonHeader } = useStaticQuery(graphql`
    query KameleonHeader {
      kameleonHeader {
        block
        menu {
          link
          label
          subMenu {
            link
            label
          }
        }
      }
    }
  `);

  const block = kameleonHeader?.block;
  if (!block) return null;

  return (
    <DynamicLoader
      component={block?.packageName}
      secondaryBlock={block?.secondaryBlock}
      blockProps={{
        ...(block?.blockProps ?? {}),
        menu: kameleonHeader?.menu,
        layoutProps: { _positionAbsolute: isAbsolute },
      }}
    />
  );
}
