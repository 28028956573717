import { Modal as SecondScreenModal } from "@kameleon-common/second-screen";
import React from "react";
import { ErrorLogger } from "../../utils/ErrorLogger";
import { KameleonGlobalTheme } from "../components/elements/kameleon-global-theme";
import { Codes } from "../config/Codes";
import { Uris } from "../config/Uris";

export const logError = (error: Error, info: { componentStack: string }) => {
  const logger = new ErrorLogger();
  logger.logError({
    name: "gatsby-theme",
    code: 500,
    message: `${error}`,
    trace: `${info}`,
  });
};
export const fallBackNoRender = () => <div />;

export const fallBackRender = () => (
  <div style={{ backgroundColor: "purple", width: "100%", height: "100vh" }}>
    <KameleonGlobalTheme>
      <SecondScreenModal
        title={Codes.jsError.title}
        description={Codes.jsError.description}
        codes={{
          buttons: {
            submit: Codes.buttons.enterCode,
          },
        }}
        onSubmit={(): void => {
          window.location.assign(Uris.secondScreen.home());
        }}
        closeDisabled
        onClose={(): void => {}}
      />
    </KameleonGlobalTheme>
  </div>
);
